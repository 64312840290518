import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Collapse, { Panel } from 'rc-collapse';

import style from './menu.module.css';
import './menu.css';

const Menu = ({ pathname }) => {
  const { contentfulNavigation } = useStaticQuery(graphql`
    {
      contentfulNavigation {
        albums {
          id
          name
          albums {
            id
            name
            slug
          }
        }
      }
    }
  `);

  let activeKey;

  if (pathname) {
    activeKey = contentfulNavigation.albums.find(({ albums }) =>
      albums.some(({ slug }) => slug === pathname.split('/')[1]),
    ).id;
  }

  return (
    <nav className={style.nav}>
      <Collapse accordion defaultActiveKey={activeKey}>
        {contentfulNavigation.albums.map(category => (
          <Panel key={category.id} header={category.name} showArrow={false}>
            {category.albums.map(album => (
              <Link key={album.id} className={style.link} to={`/${album.slug}`}>
                {album.name}
              </Link>
            ))}
          </Panel>
        ))}
      </Collapse>
      <Link
        className={style.link}
        activeClassName={style.activeLink}
        to="/contatto"
      >
        Contatto
      </Link>
    </nav>
  );
};

Menu.propTypes = {
  pathname: PropTypes.string,
};

Menu.defaultProps = {
  pathname: undefined,
};

export default Menu;
