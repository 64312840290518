import React from 'react';
import PropTypes from 'prop-types';

import style from './back-arrow.module.css';

const scrollLeft = ref => {
  if (typeof window === 'undefined') return;
  window.scrollTo(0, window.scrollY);
  ref.current.scrollTo(0, ref.current.scrollY);
};

const BackArrow = ({ scrollerRef }) => (
  <button
    className={style.button}
    type="button"
    onClick={() => scrollLeft(scrollerRef)}
  >
    <svg
      height="18"
      viewBox="0 0 586 511"
      xmlns="http://www.w3.org/2000/svg"
      className={style.svg}
    >
      <path
        className={style.path}
        d="m260.5 0h116.5l-219.5 215h428.5v81h-428.5l219 215h-116l-260.5-255.5z"
      />
    </svg>
  </button>
);

BackArrow.propTypes = {
  scrollerRef: PropTypes.shape({
    current: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
};

export default BackArrow;
