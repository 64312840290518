import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Menu from './menu';
import MenuSimple from './menu-simple';
import BackArrow from './back-arrow';

import { horizontalDragScroll } from '../utils/utils';

import './layout.css';

const Layout = ({ children, pathname, isAlbum }) => {
  const scrollerRef = useRef(null);
  const { contentfulSite } = useStaticQuery(graphql`
    {
      contentfulSite {
        title
      }
    }
  `);

  useEffect(
    () => horizontalDragScroll(document.documentElement, scrollerRef.current),
    [scrollerRef.current],
  );

  return (
    <div className="flex">
      <div className="upperContainer" ref={scrollerRef}>
        <header>
          <h1>
            <a href="/">{contentfulSite.title}</a>
          </h1>
        </header>
        <div className="flexContainer">
          {process.env.GATSBY_SITE_SARA ? (
            <Menu pathname={pathname} />
          ) : (
            <MenuSimple />
          )}
          <main>{children}</main>
        </div>
      </div>
      <footer className="footer">
        <p className="copyright">© {contentfulSite.title}</p>
        {isAlbum ? <BackArrow scrollerRef={scrollerRef} /> : null}
      </footer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string,
  isAlbum: PropTypes.bool,
};

Layout.defaultProps = {
  pathname: undefined,
  isAlbum: false,
};

export default Layout;
