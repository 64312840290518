import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import style from './menu.module.css';

const MenuSimple = () => {
  const { contentfulAlbums } = useStaticQuery(graphql`
    {
      contentfulAlbums {
        albums {
          id
          name
          slug
        }
      }
    }
  `);

  return (
    <nav className={style.nav}>
      <ul className={style.ul}>
        {contentfulAlbums.albums.map(album => (
          <li key={album.id}>
            <Link
              className={style.link}
              activeClassName={style.activeLink}
              to={`/${album.slug}`}
            >
              {album.name}
            </Link>
          </li>
        ))}
        <li>
          <Link
            className={style.link}
            activeClassName={style.activeLink}
            to="/contatto"
          >
            Contatto
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MenuSimple;
